// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do

// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

const rootUrl = 'https://v15-dev.horizons.sg';
const rootApiUrl = 'https://api-v2-dev.horizons.sg';

export const environment = {
  label: 'dev',
  applicationUrl: rootUrl,
  organizationApi: rootUrl + '/api/organization',
  fittingApi: rootApiUrl + '/fitting',
  accountApi: rootApiUrl + '/account',
  logisticApi: rootApiUrl + '/logistics',
  dossierApi: rootUrl + '/api/dossier',
  scheduleManagementApi: rootApiUrl + '/schedulemanagement',
  multilangualManagementApi: rootUrl + '/api/multilingual',
  templateManagementApi: rootApiUrl + '/templatemanagement',
  documentManagementApi: rootApiUrl + '/documentmanagement',
  sapLogisticApi: rootApiUrl + '/saplogistics',
  messagingApi: rootApiUrl + '/messaging',
  sendToAccountApi: rootApiUrl + '/sendtoaccount',
  notificationsApi: rootApiUrl + '/notification',
  codificationApi: rootApiUrl + '/codification',
  webauthorityApi: rootApiUrl + '/webauthority',
  dossierSearchApi: rootApiUrl + '/dossiersearch',
  userApi: rootApiUrl + '/usermanagement',
  taxApi: rootApiUrl + '/tax',
  casesApi: rootApiUrl + '/casesystem',
  utilProxyApi: rootApiUrl + '/utilproxy',
  auth0Domain: 'glassdrive-test.eu.auth0.com',
  auth0Identifier: 'profit-api',
  auth0ClientId: '0ojxHhEm1VH7agrTxW7BAaqkqAcrNSak',
  googleMapsApi: 'https://maps.googleapis.com/maps/api/js',
  googleMapsApiKey: 'AIzaSyBpJNGNRzogZulQ-ecKvac2PEidqLVE-vs',
  storeLocatorUrlList: [
    { key: 10, value: 'https://stage.pt.glassdrive.adyax-dev.com/horizon' },
    { key: 5, value: 'https://stage.it.glassdrive.adyax-dev.com/horizon' },
  ],
  mobileJobEnabledTenants: [5, 2, 3, 9],
  autoverOrderUrl: 'https://training-catalog.horizons.sg/autoverstorefront',
  envDeepLinkUrl: 'glassdrivedev://',
  asiturIcClaimNumberSearchProtocolId: 758,
  mstrUrl:
    'https://bi-dev.saint-gobain-autover.com/MSTR/asp/Main.aspx' +
    '?evt=3186&src=Main.aspx.3186&subscriptionID=BCF8C8DB455C074280D787983E3FB7FF&Server=Z98SW072DMST1WB&Project=Autover&Port=0&share=1',
  appInsights: {
    enable: true,
    instrumentationKey: '966c7bd8-b8d0-459b-8d6c-45eb193b2f25',
  },
  signalrEndpoint: 'https://profit-dossier-dev.azurewebsites.net/signalr'
};
