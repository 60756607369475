
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Permission } from '@hrz/core/models/enums/permission.enum';
import { AuthService } from '@hrz/core/services/auth.service';
import { ConfigService } from '@hrz/core/services/config.service';
import { CountryEnum } from '@hrz/core/enums/country.enum';

@Component({
  selector: 'hrz-side-bar',
  templateUrl: './sidebar.component.html',
  styleUrls: ["./sidebar.component.scss"],
})
export class SideBarComponent  implements OnInit, AfterViewInit {
  
  @Input() isExpended : boolean; 
  @Input() isResponsiveMode : boolean; 
  @Output() expendedChange : EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('scrollableList', { static: true }) scrollableList: ElementRef;

  public disabled = false;
  public status: { isopen: boolean } = { isopen: false };
  public isLinkedToPortugal = false;
  perm = Permission;
  hasScrollBar = false;
  public newEventsCount = 0;
  public eventsReadSubscription: any;
  public tenantUsesDiscountApprovalRequest: boolean = undefined;
  private _authorityChecked = false;
  private _userIsWebAuthority = false;

  constructor(
    private authService: AuthService,
    private readonly configService: ConfigService
  ) {
    console.log('FullLayoutComponent.constructor()');
  }

  ngAfterViewInit(): void {
    const olElement = this.scrollableList.nativeElement;

    const observer = new MutationObserver(() => {
      this.hasScrollBar =  (olElement.scrollHeight > olElement.clientHeight)
    });

    observer.observe(olElement, { childList: true, subtree: true, attributes: true });
  }

  ngOnInit(): void {
    console.log('FullLayoutComponent.ngOnInit()');
    this.authService.getTenantId().subscribe(tenantId => {
      if (tenantId === CountryEnum.Portugal) {
        this.isLinkedToPortugal = true;
      }

      this.configService.get().then(config => {
        this.tenantUsesDiscountApprovalRequest =
          !!config.TenantsUsingDiscountApprovalRequest && config.TenantsUsingDiscountApprovalRequest.includes(tenantId);
      });
    });
  }

  OnExpandChange()
  {
    this.expendedChange.emit();
  }

  public userIsWebAuthority(): boolean {
    if (!this._authorityChecked) {
      this._authorityChecked = true;
      this._userIsWebAuthority = this.authService.isWebAuthorityUser(null);
    }
    return this._userIsWebAuthority;
  }
  
}